import React from 'react';
import Layout from '../components/layout';

const Blog = () => {
    return ( 
        <Layout>
            <h1>Blog</h1>
        </Layout>
     );
}
 
export default Blog;